/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 * 
 * 
 */


 import "./src/assets/css/bootstrap.min.css"
 import "./src/assets/css/fonts.css"
 import "./src/assets/css/responsive.css"
 import "./src/assets/css/dashboard-style.css"
 import "./src/assets/css/teamlance.css"
 import "jquery/dist/jquery.min.js";
 import "popper.js/dist/popper.min";
 import "bootstrap/dist/js/bootstrap.min.js";

 const $ = require("jquery")

 export const onRouteUpdate = ({ location, prevLocation }) => {
    $(document).ready(function () {
         
    });
 }

 

export const onInitialClientRender = () => {
  $(document).ready(function () {
    
  });
}

 
